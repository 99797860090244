import {apiRequest} from "./API"

export default {
    getUnits: async (propId) =>{
        let Units = await apiRequest(`/property/${propId}/unit`, "GET");
        return Units;
    },
    updateUnit: async (u) => {
        let response;
        const {uuid, baths, bedrooms} = u;
        const payload = {
            baths,
            bedrooms
        }
        if (u?.uuid) response = await apiRequest(`/customer/unit/${uuid}`,  "PUT", payload);
        return response;
    }
};